import React from "react";
import { Link as ScrollLink } from "react-scroll";

import FooterLogo from "../assets/images/logo pixl-01.png";

const Footer = () => {
  return (
    <div>
      {/* <section className="mailchimp-one">
        <div className="container wow fadeInUp">
          <div className="inner-container">
            <div className="mailchimp-one__icon">
              <i className="apton-icon-mail"></i>
            </div>
            <form action="#" className="mailchimp-one__form">
              <input
                type="text"
                placeholder="Enter your email address"
                name="email"
              />
              <button className="thm-btn mailchimp-one__btn" type="submit">
                <span>Register Now</span>
              </button>
            </form>
          </div>
        </div>
      </section> */}

      <footer className="site-footer">
        <div className="site-footer__upper">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="footer-widget footer-widget__about">
                  <a href="/">
                    <img src={FooterLogo} width="129" alt="awesome post" />
                  </a>
                  <p>
                    Talkiko is an app where you can <br /> talk and share your
                    feelings with
                    <br /> someone without being judged
                  </p>
                </div>
              </div>

              <div className="col-lg-7">
                <div className="footer-widget__links-wrap">
                  <div className="footer-widget">
                    <h3 className="footer-widget__title">Company</h3>
                    <ul className="list-unstyled footer-widget__links-list">
                      <li>
                        <ScrollLink
                          activeClass="current"
                          to="about-us"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          About
                        </ScrollLink>
                      </li>
                      <li>
                        <a href="/termsAndConditions">Terms And Conditions</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <ScrollLink
                          activeClass="current"
                          to="contact"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          Contact
                        </ScrollLink>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-widget">
                    <h3 className="footer-widget__title">Explore</h3>
                    <ul className="list-unstyled footer-widget__links-list">
                      <li>
                        <ScrollLink
                          to="features"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          Features
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                          to="pricing"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          Pricing
                        </ScrollLink>
                      </li>
                      <li>
                        <ScrollLink
                          to="screens"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          Screens
                        </ScrollLink>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-widget">
                    <h3 className="footer-widget__title">Reach Us</h3>
                    <ul className="list-unstyled footer-widget__links-list">
                      <li>
                        <p style={{cursor:"text"}}>
                          Spectra communications - FZCO <br/> premise no 48567-001, <br/>IFZA Buisness park DDP,
                          IFZA Properties, <br/> Dubai silicon Oasis,<br/> United Arab Emirates
                        </p>
                      </li>
                      <li>
                      <a href="mailto:support@talkiko.com">support@talkiko.com</a> 
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3 d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-sm-center">
                <div className="footer-widget">
                  <div className="footer-widget__social">
                    <a href="#none">
                      <i className="fab fa-facebook-square"></i>
                    </a>
                    <a href="#none">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#none">
                      <i className="fab fa-instagram"></i>
                    </a>
                    <a href="#none">
                      <i className="fab fa-pinterest-p"></i>
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="site-footer__bottom">
          <div className="container text-center">
            <p>
              © copyright 2024 by{" "}
              <a href="https://www.zfloc.com/" target="_blank">
                ZFLOC TECHNOLOGIES
              </a>
            </p>
          </div>
        </div> */}
      </footer>
    </div>
  );
};

export default Footer;
